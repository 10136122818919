<div class="edit-modal p-4">
  <header class="row pb-2">
    <div class="col">
      <h3>Editar configuração de empresa</h3>
    </div>
    <div class="col-1 right">
      <strong class="f-16px closed-icon" (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </strong>
    </div>
  </header>

  <div class="container pt-3">
    <form>
      <div class="flex">
        <label for="logo-upload" class="mb-1 d-block mt-3">Logo da Empresa</label>
        <div class="form-group mt-0 grid-image">
          <div class="d-flex flex-column align-items-start mt-2">
            <img *ngIf="userCustom.image" [src]="userCustom.image.baseUrl + userCustom.image.file" alt="Logo da Empresa" class="company-logo mb-1 ml-1" />
            <img *ngIf="!userCustom.image" src="/assets/images/placeholder.png" alt="Sem dados" class="company-logo mb-1 ml-1 empty">
            <input type="file" id="fileUpload" (change)="onFileSelected($event)" style="display: none;" />
            <button mat-stroked-button color="primary" (click)="triggerFileInput()" class="mt-2 button">Upload Logo</button>
          </div>
          <div class="details-image">
            <h2>
              Adicione a identidade visual da sua empresa
            </h2>
            <p>
              Para garantir, sua imagem precisa ter o tamanho de 000x000 pixels
              no posicionamento horizontal.
            </p>
          </div>
        </div>
      </div>


      <mat-form-field class="full-width">
        <mat-label>Nome da Empresa</mat-label>
        <input matInput [(ngModel)]="userCustom.name" name="name" placeholder="Digite o nome da empresa" />
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>slug</mat-label>
        <input matInput [(ngModel)]="userCustom.slug" name="slug" placeholder="Digite o slug da empresa" />
      </mat-form-field>

      <div class="mt-4 d-flex justify-content-end custom-buttom-edit">
        <button class="btn btn-secondary" (click)="closeModal()">
          Cancelar
        </button>
        <button class="btn btn-primary me-2" (click)="saveCompany()">
          Salvar
        </button>
      </div>
    </form>
  </div>
</div>
