import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  initialized = false;
  currentRout = '';

  constructor(private router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRout = event.urlAfterRedirects || event.url;

        if (!this.currentRout.includes('/aprovar-orcamento')) {
          this.checkToken();
        } else {
          // this.initialized = true;
        }
      }
    });
  }

  ngOnInit(): void {
    // Ensure the token check is only done when not on "/aprovar-orcamento"
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (!event.urlAfterRedirects.includes('/aprovar-orcamento')) {
          this.checkToken();
        } else {
          // this.initialized = true;
        }
      }
    });
  }

  checkToken() {
    if (sessionStorage['user']) {
      this.initialized = true;
    } else {
      this.initialized = false;
      this.router.navigateByUrl('login');
    }
  }
}
