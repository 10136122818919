import { Routes } from "@angular/router";

export const systemRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'aprovar-orcamento',
    loadChildren: () => import('../../pages/approved-order/approved-order.module').then(m => m.ApprovedOrderModule),
  },
  {
    path: 'login',
    loadChildren: () => import('../../pages/login-page/login-page.module').then(m => m.LoginPageModule),
  },
  {
    path: 'painel',
    loadChildren: () => import('../../pages/dashboard-page/dashboard-page.module').then(m => m.DashboardPageModule),
  },
  {
    path: 'ordem-de-servico',
    loadChildren: () => import('../../pages/service-order-page/service-order-page.module').then(m => m.ServiceOrderPageModule),
  },
  {
    path: 'financeiro',
    loadChildren: () => import('../../pages/finantial-page/finantial-page.module').then(m => m.FinantialPageModule),
  },
  {
    path: 'agenda',
    loadChildren: () => import('../../pages/calendar-page/calendar-page.module').then(m => m.CalendarPageModule),
  },
  {
    path: 'geolocalizacao',
    loadChildren: () => import('../../pages/geolocation-page/geolocation-page.module').then(m => m.GeolocationPageModule),
  },
  {
    path: 'termos-de-uso',
    loadChildren: () => import('../../pages/use-terms-page/use-terms-page.module').then(m => m.UseTermsPageModule),
  },
  {
    path: 'ajuda',
    loadChildren: () => import('../../pages/help-page/help-page.module').then(m => m.HelpPageModule),
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },
];
