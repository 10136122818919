<div>
  <div *ngIf="!isMobileView" class="bar" [ngClass]="{'hovered-side-bar': hoveredBar, 'side-bar': !hoveredBar}">
    <div class="row justify-content-center pb-40px logo">
      <div class="{{ hoveredBar ? 'col-8' : 'col-10' }}">
        <img src="{{ hoveredBar ? '/assets/icons/keepee-logo.svg' : '/assets/icons/mini-logo.svg' }}" alt="" class="w-80">
      </div>
    </div>
    <div class="menu-item" *ngFor="let item of menuList"
      [ngClass]="{'actived-item': currentRout == item.path && !hoveredBar, 'hovered-actived-item': currentRout == item.path && hoveredBar, 'hovered-menu-item': hoveredBar, 'd-none': !hoveredBar && item.isTitle}"
      (click)="goToPage(item.path)"
    >
      <img src="{{ currentRout == item.path && hoveredBar ? item.activeIcon : item.icon }}" alt="">
      <span *ngIf="hoveredBar && !item.isTitle" class="f-16px ml-16px">{{item.title}}</span>
      <strong *ngIf="hoveredBar && item.isTitle" class="f-16px ml-0">{{item.title}}</strong>
      <div *ngIf="item.isLine" class="line-border"></div>
    </div>
    <div [ngClass]="{'logout-sect': !hoveredBar, 'hovered-logout-sect': hoveredBar}">
      <button class="btn btn-fixed" (click)="hoveredBar = !hoveredBar">
        <i class="bi bi-arrow-bar-right" *ngIf="!hoveredBar"></i>
        <i class="bi bi-arrow-bar-left" *ngIf="!!hoveredBar"></i>
      </button>
    </div>
  </div>

  <div *ngIf="isMobileView" class="bar-mobile">
    <div class="pt-2 justify-content-center logo">
      <div class="col-10 px-2 py-1">
        <img src="/assets/icons/mini-logo.svg" alt="" class="w-80">
      </div>
    </div>
    <button class="menu-mobile" (click)="hoveredBar = !hoveredBar">
      Abrir
    </button>
    <div *ngIf="hoveredBar" class="menu-list">
      <div class="menu-item" *ngFor="let item of menuList"
        [ngClass]="{'actived-item': currentRout == item.path, 'hovered-actived-item': currentRout == item.path}"
        (click)="goToPage(item.path); closeMenu()"
      >
        <img src="{{ currentRout == item.path ? item.activeIcon : item.icon }}" alt="">
        <span class="f-16px ml-16px">{{item.title}}</span>
        <div *ngIf="item.isLine" class="line-border"></div>
      </div>
    </div>
  </div>
</div>
