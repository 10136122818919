import { Component, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WhiteLabelService } from 'src/app/services/whitelabel/whitelabel.service';

@Component({
  selector: 'app-edit-company-modal',
  templateUrl: './edit-company-modal.component.html',
  styleUrls: ['./edit-company-modal.component.scss'],
})
export class EditCompanyModalComponent implements OnInit {
  userCustom: { id: string, name: string; slug: string; image: { id: string; baseUrl: string; file: string } | null } = {
    id: '',
    name: '',
    slug: '',
    image: null
  };
  isLoadingUpload = false;

  constructor(private whiteLabelService: WhiteLabelService, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    const companyData = JSON.parse(atob(sessionStorage['company-custom']));

    console.log('companyData', companyData);

    if (companyData) {
      this.userCustom = {
        id: companyData.id,
        name: companyData.name || '',
        slug: companyData.slug || '',
        image: companyData.image || null
      };
    }
  }

  triggerFileInput(): void {
    const fileInput = document.getElementById('fileUpload') as HTMLElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const formData = new FormData();
      formData.append('file', file);
      this.isLoadingUpload = true;

      this.whiteLabelService.post('/files-images', formData).subscribe({
        next: (res) => {
          this.userCustom.image = { id: res.id, baseUrl: res.baseUrl, file: res.file };
          this.isLoadingUpload = false;
        },
        error: (err) => {
          console.error('Erro ao fazer upload da imagem:', err);
          this.isLoadingUpload = false;
        }
      });
    } else {
      alert('Por favor, selecione uma imagem válida.');
    }
  }

  saveCompany(): void {
    if (!this.userCustom.name || !this.userCustom.slug) {
      alert('O nome da empresa e o slug são obrigatórios.');
      return;
    }

    const updatedCompanyData = {
      name: this.userCustom.name,
      slug: this.userCustom.slug,
      thumb: this.userCustom.image?.id
    };

    this.isLoadingUpload = true;

    this.whiteLabelService.patch(`/Company/${this.userCustom.id}`, updatedCompanyData).subscribe({
      next: (res) => {
        console.log(res);
        sessionStorage.setItem('company-custom', btoa(JSON.stringify(res)));
        sessionStorage.setItem('company-custom', btoa(JSON.stringify(this.userCustom)));
        this.isLoadingUpload = false;
        this.activeModal.close(res);
      },
      error: (err) => {
        console.error('Erro ao atualizar o perfil da empresa:', err);
        this.isLoadingUpload = false;
      }
    });
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
