import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { iconMenu } from 'src/app/shared/utils/icon-menu';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @Input() currentRout = '';
  hoveredBar = false;
  menuList = iconMenu;
  isMobileView = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.checkWindowWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowWidth();
  }

  checkWindowWidth() {
    this.isMobileView = window.innerWidth < 768;
  }

  goToPage(route: string) {
    this.router.navigate([route]);
  }

  closeMenu() {
    this.hoveredBar = false;
  }
}
