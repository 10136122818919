import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router'; // Router to redirect

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private router: Router) {} // Inject the Router

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      const tokenStr = sessionStorage.getItem('token');
      if (!tokenStr) {
        this.clearStorage();
        return next.handle(req);
      }

      const token = JSON.parse(atob(tokenStr));
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          Application: `PortalCliente`,
        }
      });

      return next.handle(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.clearStorage();
            this.router.navigate(['/login']);
          }
          return throwError(error);
        })
      );
    } catch (error) {
      console.error('Erro ao processar o token:', error);
      this.clearStorage();
      return next.handle(req);
    }
  }

  private clearStorage() {
    sessionStorage.removeItem('__userKey');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    localStorage.removeItem('__userKey');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }
}
