import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SideBarModule } from './shared/layout/components/side-bar/side-bar.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { HeaderModule } from './shared/layout/components/header/header.module';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OverlayContainer } from '@angular/cdk/overlay';
import { GoogleMapsModule } from '@angular/google-maps'; // Apenas GoogleMapsModule
import { environment } from '../environments/environment'; // Importa o environment

import { TokenInterceptorService } from './token-interceptor/token-interceptor.service';

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    SideBarModule,
    FontAwesomeModule,
    HeaderModule,
    ToastrModule.forRoot(),
    NgbModule,
    GoogleMapsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    { provide: OverlayContainer, useClass: OverlayContainer }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
