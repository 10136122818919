import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificacoesService {

  baseUri = environment.baseApi + environment.Notificacoes_Base

  constructor(
    private http: HttpClient
  ) { }

  get(url:string):Observable<any> {
    return this.http.get<any>(`${this.baseUri}${url}`).pipe(
      tap()
    )
  }
  getBy(url:string, byProperty:string):Observable<any> {
    return this.http.get<any>(`${this.baseUri}${url}${byProperty}`).pipe(
      tap()
    )
  }
  post(url:string, body:any):Observable<any> {
    return this.http.post<any>(`${this.baseUri}${url}`, body).pipe(
      tap()
    )
  }
  put(url:string, body:any):Observable<any> {
    return this.http.put<any>(`${this.baseUri}${url}`, body).pipe(
      tap()
    )
  }
  patch(url:string, body:any):Observable<any> {
    return this.http.patch<any>(`${this.baseUri}${url}`, body).pipe(
      tap()
    )
  }
  delete(url:string, body:any):Observable<any> {
    return this.http.delete<any>(`${this.baseUri}${url}`, body).pipe(
      tap()
    )
  }
}
