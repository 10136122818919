import { RouterModule } from '@angular/router';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideBarComponent } from './side-bar.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbModalModule,
    RouterModule
  ],
  declarations: [SideBarComponent],
  exports: [SideBarComponent],
})
export class SideBarModule { }
